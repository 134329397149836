.btn-rounded {
    border-radius: 30px !important;
}

// CSS to make the single value of the autocomplete on mutliple lines if too large
.gpa_s__single-value {
  position: relative !important;
  transform: none !important;
  white-space: pre-line !important;
  margin: 0 !important;
}

.gpa_s__menu {
  z-index: 1000 !important;
}

// Your variable overrides
$body-color: #474849;
$primary: #1F8A70;
$form-label-color: #808080;


.form-floating > label {
  color: $form-label-color;
}

// Add margin when scroll to not have the navbar overlap the content
@media (min-width: 1201px) {
  section > #why {
    scroll-margin-top: 0px;
  }
  section > #solutions {
    scroll-margin-top: 100px;
  }
}
@media (max-width: 1200px) {
  section > #why {
    scroll-margin-top: 200px;
  }
  section > #solutions {
    scroll-margin-top: 300px;
  }
}

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

.btn-primary {
  color: white;
}
.btn-primary:hover {
  // color: #2e2e2e;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
}
.btn-outline-primary:hover {
  // color: #2e2e2e;
}